'use client';

import { useState, Suspense, useCallback, useEffect } from 'react';
import Button from '@ui/components/ui/button/Button';
import { Card, CardContentRaw, DateTimePicker } from '@ui/components';
import { SearchAirport } from './search-airport';
import { Icon, IconName } from '@icons/icons';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { getCookie } from 'cookies-next';
import { type SearchAirportFieldsFragment } from '../../../graphql/generated/graphql';
import {
  ListDropdownItem,
  CustomListDropdown,
} from '../../custom-components/custom-dropdown/custom-list-dropdown';

export const LandingSearchCard = ({
  airports,
}: {
  airports: Array<SearchAirportFieldsFragment>;
}) => {
  const t = useTranslations('search_card');
  const router = useRouter();
  const getDefaultDeparture = () => {
    const today = new Date();
    today.setHours(12, 0, 0, 0); // Set to 12:00 noon
    return today;
  };

  const getDefaultArrival = (departureDate: Date) => {
    const defaultArrival = new Date(departureDate); // Clone the departure date
    defaultArrival.setDate(departureDate.getDate() + 1); // Add 1 day to the departure date
    defaultArrival.setHours(12, 0, 0, 0); // Set time to 12:00 noon
    return defaultArrival;
  };
  const [dateDeparture, setDateDeparture] = useState<Date | undefined>(
    getDefaultDeparture()
  );
  const [dateArrival, setDateArrival] = useState<Date | undefined>(
    getDefaultArrival(getDefaultDeparture()) // Pass the default departure date
  );
  const [selectedAirport, setSelectedAirport] = useState<string | null>();
  const [selectedCarType, setSelectedCarType] = useState<string | null>();
  const [selectedTerminal, setSelectedTerminal] = useState<string | null>();
  const [airportTerminals, setAirportTerminals] = useState<Array<ListDropdownItem>>(
    []
  );
  const [error, setError] = useState<string | null>();
  const [isTerminalDropdownOpen, setIsTerminalDropdownOpen] = useState(false);
  const [isDeparturePickerOpen, setIsDeparturePickerOpen] = useState(false);
  const [isArrivalPickerOpen, setIsArrivalPickerOpen] = useState(false);
  const [localeCalendar, setLocaleCalendar] = useState<string>('en');

  useEffect(() => {
    const langCookie = getCookie('lang') as string; // Fetch 'lang' cookie
    if (langCookie) {
      setLocaleCalendar(langCookie); // Update localeCalendar dynamically
    }
  }, []);
  const [isVehicleTypeOpen, setIsVehicleTypeOpen] = useState(false);
  const icon: IconName = 'TerminalIcon';

  const handleAirportSelect = useCallback(
    (airportName: string) => {
      const terminals = airports
        .flatMap(country =>
          country.airport?.name === airportName ? country.airport?.terminals : []
        )
        .filter(terminal => terminal?.name)
        .map(terminal => ({
          text: terminal?.name || 'Unknown Terminal',
          value: terminal?.name || 'Unknown Terminal',
          iconName: icon, // Ensure 'icon' is defined in your component scope
        }));

      setSelectedAirport(airportName); // Update selected airport
      setAirportTerminals(terminals); // Update terminal list

      if (terminals.length > 0) {
        setIsTerminalDropdownOpen(true); // Automatically open the terminal dropdown
      } else {
        setIsTerminalDropdownOpen(false); // Close the dropdown if no terminals
        setIsDeparturePickerOpen(true);
      }

      // Optionally close other pickers
      setIsArrivalPickerOpen(false);
    },
    [airports, icon] // Include all necessary dependencies
  );

  const handleDateDepartureChange = (date: Date | undefined) => {
    setDateDeparture(date);
    if (date) {
      const newDefaultArrival = getDefaultArrival(date);
      if (!dateArrival || dateArrival < newDefaultArrival) {
        setDateArrival(newDefaultArrival); // Update the arrival date if it's invalid
      }
      setIsDeparturePickerOpen(false);
      setIsArrivalPickerOpen(true);
    }
  };

  const handleDateArrivalChange = (date: Date | undefined) => {
    if (date && date < dateDeparture!) {
      return;
    }
    setDateArrival(date);
    if (date) {
      setIsArrivalPickerOpen(false);
      setIsVehicleTypeOpen(true);
    }
  };

  const vehicleItems: Array<ListDropdownItem> = [
    {
      text: 'Passenger Car',
      value: 'passenger',
      iconName: 'DirectionsCarFilledOutlinedIcon',
    },
    {
      text: 'Van',
      value: 'van',
      iconName: 'AirportShuttleOutlinedIcon',
    },
    {
      text: 'Caravan',
      value: 'caravan',
      iconName: 'CaravanIcon',
    },
    {
      text: 'Bus',
      value: 'bus',
      iconName: 'DirectionsBusFilledOutlinedIcon',
    },
    {
      text: 'Motorcycle',
      value: 'motorcycle',
      iconName: 'TwoWheelerOutlinedIcon',
    },
    {
      text: 'Truck',
      value: 'truck',
      iconName: 'LocalShippingOutlinedIcon',
    },
  ];

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedAirport || !dateDeparture || !dateArrival || !selectedCarType) {
      // TODO: change this later to check all the fields are filled
      // eslint-disable-next-line no-console
      setError(t('search_airport.error'));
      return;
    }

    // Construct query parameters dynamically
    const queryParams = new URLSearchParams({
      airport: selectedAirport,
      departure: dateDeparture.toISOString(),
      arrival: dateArrival.toISOString(),
      vehicle: selectedCarType,
    });

    // Include terminal only if selected
    if (selectedTerminal) {
      queryParams.append('terminal', selectedTerminal);
    }

    // Navigate to the new URL
    router.push(`/compare?${queryParams.toString()}`);
  };

  // Render the component
  return (
    <div className="relative z-40 h-full">
      <form action="#" onSubmit={handleSubmit} className="h-full">
        <Card shadow size="medium">
          <CardContentRaw variant="default" middle>
            <div className="flex flex-col space-y-5 w-full">
              <Suspense>
                <SearchAirport
                  airports={airports}
                  onAirportSelect={handleAirportSelect}
                  isAutoScroll
                />
              </Suspense>
              {/* Step 2: show the terminal dropdown only if there are terminals */}
              {airportTerminals.length > 0 && (
                <CustomListDropdown
                  items={airportTerminals}
                  variantContent="default"
                  title={t('terminal_type.title')}
                  placeholder={t('terminal_type.placeholder')}
                  roundedContent="bottom"
                  iconLeft={<Icon name="TerminalIcon" iconColor="default" />}
                  iconRight={
                    <Icon
                      name="KeyboardArrowDownRoundedIcon"
                      iconColor="dark"
                      size={8}
                    />
                  }
                  size="large"
                  onChange={value => {
                    setSelectedTerminal(value); // Set the terminal
                    setIsTerminalDropdownOpen(false); // Close terminal dropdown
                    // Delay opening the departure picker slightly to avoid glitch
                    setTimeout(() => {
                      setIsDeparturePickerOpen(true);
                    }, 100); // Adjust delay based on dropdown animation duration
                  }}
                  onOpenChange={isOpen => setIsTerminalDropdownOpen(isOpen)} // Sync open state
                  isOpen={isTerminalDropdownOpen} // Explicitly control dropdown visibility
                />
              )}

              {/* Step 2: Select Departure Date */}
              <DateTimePicker
                localeCalendar={localeCalendar}
                timePlaceholder={t('time')}
                hourPlaceholder={t('hour')}
                value={dateDeparture}
                onChange={handleDateDepartureChange}
                calendarVariant="overlay"
                calendarTitle={t('departure.calendar_title')}
                title={t('departure.label')}
                displayDateFormat="dd-MM-yyyy"
                isOpen={isDeparturePickerOpen}
                position="departure"
              />

              {/* Step 3: Select Arrival Date */}
              <DateTimePicker
                localeCalendar={localeCalendar}
                timePlaceholder={t('time')}
                hourPlaceholder={t('hour')}
                value={dateArrival}
                onChange={handleDateArrivalChange}
                calendarVariant="overlay"
                calendarTitle={t('arrival.calendar_title')}
                title={t('arrival.label')}
                displayDateFormat="dd-MM-yyyy"
                isOpen={isArrivalPickerOpen}
                position="arrival"
                minDate={dateDeparture}
              />

              {/* Step 4: Select Vehicle Type */}
              <CustomListDropdown
                items={vehicleItems}
                variantContent="default"
                title={t('vehicle_type.title')}
                valuePlacement="bottom"
                placeholder={t('vehicle_type.placeholder')}
                roundedContent="bottom"
                iconLeft={
                  <Icon name="DirectionsCarFilledOutlinedIcon" iconColor="default" />
                }
                size="large"
                onChange={value => {
                  setSelectedCarType(value); // Update the selected car type
                  setIsVehicleTypeOpen(false); // Close dropdown after selection
                }}
                isOpen={isVehicleTypeOpen} // Automatically open after the arrival picker
                onOpenChange={isOpen => setIsVehicleTypeOpen(isOpen)} // Sync state with user interactions
              />

              {error && (
                <p className="text-red-500 bg-red-100 border border-red-500 text-center py-2 px-4 rounded-lg">
                  {error}
                </p>
              )}

              {/* Submit Button */}
              <div className="flex justify-center">
                <Button
                  type="submit"
                  variant="primary"
                  size="extraLarge"
                  radius="fullRounded"
                >
                  {t('search_button')}
                </Button>
              </div>
            </div>
          </CardContentRaw>
        </Card>
      </form>
    </div>
  );
};
