'use client';
import { useState, useEffect } from 'react';
import { Icon } from '../../../../icons/src/icons';

export const StickyArrow = ({ variant = 'default' }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const triggerHeight = 200; // Arrow disappears after this height
    if (scrollTop >= triggerHeight) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const handleClick = () => {
    window.scrollBy({
      top: window.innerHeight, // Scrolls by one viewport height
      behavior: 'smooth',
    });
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`z-10 absolute bottom-10 w-full flex justify-center transition-opacity duration-300 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div onClick={handleClick} className="cursor-pointer">
        <Icon
          name="KeyboardDoubleArrowDownRoundedIcon"
          iconColor={variant === 'white' ? 'light' : 'darkBlue'}
          size={12}
          className="animate-bounce"
        />
      </div>
    </div>
  );
};
