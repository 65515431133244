'use client';
import React, { useState } from 'react';
import { Container } from '@ui/components/ui/container';
import Button from '@ui/components/ui/button/Button';
import { Icon } from '@icons/icons';
import { Card } from '@ui/components/ui/card';
import { useTranslations } from 'next-intl';

export const ClientReview = ({ host }: { host: string }) => {
  const reviews = [
    {
      id: 1,
      name: 'Tom',
      description: 'Easy to find, fast service, great.',
      rating: 5,
    },
    {
      id: 2,
      name: 'Tom',
      description: 'Easy to find, fast service, great.',
      rating: 4.5,
    },
    {
      id: 3,
      name: 'Tom',
      description: 'Easy to find, fast service, great.',
      rating: 4.5,
    },
    {
      id: 4,
      name: 'Tom',
      description: 'Easy to find, fast service, great. Super cool service.',
      rating: 4.5,
    },
    {
      id: 5,
      name: 'Tom',
      description: 'Easy to find, fast service, great. Super cool service.',
      rating: 4.5,
    },
    {
      id: 6,
      name: 'Tom',
      description: 'Easy to find, fast service, great. Super cool service.',
      rating: 4.5,
    },
  ];

  const [visibleReviews, setVisibleReviews] = useState(4);
  const [isExpanded, setIsExpanded] = useState(false);
  const t = useTranslations('what_clients_say');

  const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;

    return (
      <div className="flex items-center">
        {Array.from({ length: fullStars }, (_, i) => (
          <Icon key={i} name="StarIcon" size={6} iconColor="yellow" />
        ))}
        {halfStar && <Icon name="StarHalfIcon" size={6} iconColor="yellow" />}
      </div>
    );
  };

  const handleToggleReviews = () => {
    if (isExpanded) {
      setVisibleReviews(4);
    } else {
      setVisibleReviews(reviews.length);
    }
    setIsExpanded(prev => !prev);
  };

  return (
    <section
      id="reviews-section"
      className="pt-10 lg:pb-10 bg-primaryBackground pb-10"
    >
      <Container>
        <span className="text-xl lg:text-3xl font-normal">
          {t('title')} {host}
        </span>

        <div className="-mx-4 lg:-mx-8 flex overflow-x-auto gap-4 scroll-smooth no-scrollbar lg:grid lg:grid-cols-4 lg:gap-6 p-8">
          {reviews.slice(0, visibleReviews).map(review => (
            <Card key={review.id} shadow variant="review" size="medium">
              <div className="flex flex-col h-full justify-between items-center p-2">
                <div>{renderStars(review.rating)}</div>
                <p className="text-md lg:text-xl font-normal mt-4 text-center flex-grow flex items-center justify-center py-2">
                  {review.description}
                </p>
                <p className="text-md font-medium mt-4 lg:text-xl pt-2">
                  {review.name}
                </p>
              </div>
            </Card>
          ))}
        </div>

        <div className="flex justify-center mt-10">
          <Button
            variant="primary"
            icon="ReviewsOutlinedIcon"
            iconSize={5}
            onClick={handleToggleReviews}
            size="large"
            iconColor="inherit"
          >
            {isExpanded ? t('read_less_reviews') : t('read_more_reviews')}
          </Button>
        </div>
      </Container>
    </section>
  );
};
