/* eslint-disable react/forbid-component-props */
'use client';
import React, { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@ui/components/ui/select';
import { Icon, IconName, IconSize } from '@icons/icons';

export interface ListDropdownItem {
  text: string;
  value: string;
  iconName?: IconName;
}

interface CustomListDropdownProperties {
  items: Array<ListDropdownItem>;
  valuePlacement?: 'bottom' | 'side';
  variant?: 'default' | 'transparent';
  triggerVariant?: 'default' | 'secondary' | 'white';
  title?: string;
  placeholder?: string;
  iconRight?: JSX.Element;
  iconLeft?: JSX.Element;
  size?: 'normal' | 'small' | 'medium' | 'large' | 'noPadding';
  iconSize?: keyof typeof IconSize;
  titleContent?: string;
  variantContent?: 'default' | 'flex';
  marginTopContent?: number;
  roundedContent?: 'none' | 'small' | 'medium' | 'large' | 'full' | 'bottom';
  selectItemVariant?: 'default' | 'outlined';
  onChange: (value: string) => void;
  shouldHideSelectedItem?: boolean;
  shouldHideIcons?: boolean;
  isFullRound?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  isOpen?: boolean;
}

export const CustomListDropdown = ({
  items,
  title,
  placeholder,
  valuePlacement = 'bottom',
  triggerVariant = 'default',
  iconRight,
  iconLeft,
  size = 'medium',
  titleContent = '',
  variant = 'default',
  iconSize = 8,
  isFullRound = true,
  marginTopContent,
  variantContent = 'flex',
  roundedContent = 'large',
  selectItemVariant = 'default',
  shouldHideSelectedItem = false,
  shouldHideIcons = false,
  onChange,
  onOpenChange,
  isOpen,
}: CustomListDropdownProperties) => {
  const [selectedItem, setSelectedItem] = useState<ListDropdownItem | undefined>();

  const [internalOpen, setInternalOpen] = useState(false);
  const isDropdownOpen = isOpen ?? internalOpen;

  const handleOpenChange = (newState: boolean) => {
    setInternalOpen(newState);
    onOpenChange?.(newState);
  };

  return (
    <Select
      onValueChange={value => {
        const selected = items.find(item => item.value === value);
        if (selected) {
          setSelectedItem(selected);
          onChange(selected.value);
        }
      }}
      open={isDropdownOpen}
      onOpenChange={handleOpenChange}
    >
      <SelectTrigger
        size={size}
        variant={triggerVariant}
        className="truncate w-full overflow-hidden text-ellipsis whitespace-nowrap"
        iconLeft={
          !shouldHideIcons && selectedItem ? (
            <Icon
              iconColor="default"
              name={(selectedItem.iconName as IconName) || 'default'}
              size={iconSize}
            />
          ) : (
            iconLeft
          )
        }
        iconRight={
          !shouldHideIcons && shouldHideSelectedItem && selectedItem ? (
            <Icon
              iconColor="default"
              name={(selectedItem.iconName as IconName) || 'default'}
              size={iconSize}
            />
          ) : (
            iconRight
          )
        }
        fullRound={isFullRound}
        fullWidth
        shouldHideIcon={shouldHideIcons}
        aria-label={title || 'Select an option'}
      >
        {variant === 'default' ? (
          <div
            className={`flex ${
              valuePlacement === 'side'
                ? 'flex-row items-center'
                : 'flex-col text-left justify-start'
            }`}
          >
            {valuePlacement === 'side' ? (
              <span className="text-sm text-eyebrowText leading-tight text-bold truncate">
                {title && `${title} `}
                <span className={shouldHideSelectedItem ? 'hidden lg:inline' : ''}>
                  {selectedItem ? selectedItem.text : placeholder}
                </span>
              </span>
            ) : (
              <>
                {title && (
                  <span className="text-sm text-eyebrowText leading-tight">
                    {title}
                  </span>
                )}
                {shouldHideSelectedItem ? (
                  <SelectValue placeholder={placeholder} />
                ) : (
                  <span className="text-bold">
                    {selectedItem ? selectedItem.text : placeholder}
                  </span>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            <span className="block text-base font-normal text-dotBackgroundActive">
              {title}
            </span>
            <span className="text-primaryText text-sm">
              {selectedItem ? selectedItem.text : placeholder}
            </span>
          </div>
        )}
      </SelectTrigger>

      <SelectContent
        variant={variantContent}
        title={titleContent}
        rounded={roundedContent}
        marginTop={marginTopContent}
      >
        {items.map(item => (
          <React.Fragment key={item.value}>
            <SelectItem
              key={item.value}
              value={item.value}
              iconName={
                item.iconName ? (
                  <Icon
                    iconColor={
                      selectItemVariant === 'default' ? 'default' : 'inherit'
                    }
                    name={item.iconName}
                    size={6}
                  />
                ) : undefined
              }
              variant={selectItemVariant}
              className={
                selectItemVariant === 'default'
                  ? selectedItem?.value === item.value
                    ? 'border-l-2 border-transparent border-primaryColour bg-gray-100'
                    : ''
                  : selectedItem?.value === item.value
                    ? 'bg-blue-100'
                    : ''
              }
            >
              <span className="cursor-pointer">{item.text}</span>
            </SelectItem>
          </React.Fragment>
        ))}
      </SelectContent>
    </Select>
  );
};
